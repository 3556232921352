import { useEffect, useState } from 'react'

export const useBreakpoint = () => {
  // breakpoint XL
  const breakpointXL = 1280
  const breakpointLG = 1024
  const breakpointMD = 768
  const breakpointSM = 640
  const breakpointXS = 480

  const [isMobile, setIsMobile] = useState(undefined)
  const [isTablet, setIsTablet] = useState(undefined)
  const [isDesktop, setIsDesktop] = useState(undefined)
  const [isScreenLg, setIsScreenLg] = useState(undefined)
  const [isScreenMd, setIsScreenMd] = useState(undefined)
  const [isScreenSm, setIsScreenSm] = useState(undefined)
  const [isScreenXs, setIsScreenXs] = useState(undefined)

  const setBreakpoints = () => {
    if (typeof window !== 'undefined') {
      setIsDesktop(
        window.matchMedia(`(min-width: ${breakpointXL - 1}px)`).matches
      )
      setIsMobile(
        window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches
      )
      setIsTablet(
        window.matchMedia(`(max-width: ${breakpointLG - 1}px)`).matches
      )
      setIsScreenLg(
        window.matchMedia(`(max-width: ${breakpointLG - 1}px)`).matches &&
          window.matchMedia(`(min-width: ${breakpointMD}px)`).matches
      ) //lg
      setIsScreenMd(
        window.matchMedia(`(max-width: ${breakpointMD - 1}px)`).matches &&
          window.matchMedia(`(min-width: ${breakpointSM}px)`).matches
      ) //md
      setIsScreenSm(
        window.matchMedia(`(max-width: ${breakpointSM - 1}px)`).matches &&
          window.matchMedia(`(min-width: ${breakpointXS}px)`).matches
      ) //sm
      setIsScreenXs(
        window.matchMedia(`(max-width: ${breakpointXS - 1}px)`).matches
      ) //xs && xs-
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setBreakpoints()
      window.addEventListener('resize', setBreakpoints)
      return () => {
        window.removeEventListener('resize', setBreakpoints)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  return {
    isMobile,
    isTablet,
    isDesktop,
    isScreenLg,
    isScreenMd,
    isScreenSm,
    isScreenXs,
  }
}
