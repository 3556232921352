import React from 'react'
import tw, { styled, css } from 'twin.macro'

export const Button = ({
  variant = 'primary', // primary, secondary, third, secondaryTransparent
  size = 'medium', //medium, large, small, wide
  withArrow = false,
  border = '#f5f5f5',
  children,
  ...props
}) => {
  return (
    <StyledButton variant={variant} size={size} {...props}>
      {!!withArrow && (
        <span className="relative flex items-center justify-center">
          {children}{' '}
          {(size === 'small' || size === 'medium') && (
            <span className="ml-4">
              <ButtonArrowMobile />
            </span>
          )}
          {size !== 'small' && size !== 'medium' && (
            <span className="w-10 lg:w-[50px] ml-4 lg:ml-5">
              <span className="block md:hidden">
                <ButtonArrowMobile />
              </span>
              <span className="hidden md:block">
                <ButtonArrow />
              </span>
            </span>
          )}
        </span>
      )}
      {!withArrow && children}
    </StyledButton>
  )
}

export const ButtonArrow = ({ className = '' }) => (
  <svg
    width="100%"
    height="19"
    viewBox="0 0 50 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path d="M0 9.44238H48.8469" />
    <path d="M40.5599 0.999714L49.0025 9.44238L40.5599 17.8851" />
  </svg>
)

export const ButtonArrowMobile = ({ className = '' }) => (
  <svg
    width="31"
    height="20"
    viewBox="0 0 31 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path d="M0 10.2266H29.3469" />
    <path d="M21.0599 1.18085L29.5025 10.2266L21.0599 19.2723" />
  </svg>
)

export const third = tw`text-xl text-black bg-white border-white `

export const common = css`
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: 0%;
  transition: background-color 0.25s cubic-bezier(0.77, 0.2, 0.05, 1),
    0.25s cubic-bezier(0.77, 0.2, 0.05, 1);
  transition-timing-function: ease-in;
  @media (hover: hover) {
    &:hover {
      background-position: -600%;
    }
  }
`

export const primaryButton = css`
  color: black;
  background: rgba(0, 0, 0, 0.95);
  background-image: linear-gradient(#7bff8d 0%, #7bff8d 100%);
  border: 2px solid #f5f5f5;
  ${common}
`
export const secondaryButton = css`
  color: black;
  background: rgba(0, 0, 0, 0.95);
  background-image: linear-gradient(#f5f5f5 0%, #f5f5f5 100%);
  border: 1px solid black;
  ${common}
`
export const greenButton = css`
  color: black;
  background: rgba(0, 0, 0, 0.95);
  background-image: linear-gradient(#7bff8d 0%, #7bff8d 100%);
  border: 1px solid black;
  ${common}
`

const StyledButton = styled.button`
  ${tw`relative inline-block text-base rounded-30 sm:text-lg lg:text-xl`}
  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return primaryButton
      case 'secondary':
        return secondaryButton
      case 'green':
        return greenButton
      case 'third':
        return third
      default:
        return primaryButton
    }
  }}
  ${({ size }) => {
    switch (size) {
      case 'small':
        return tw`px-9 py-9.5px lg:py-3.5`
      case 'medium':
        return tw`py-2 lg:py-4 w-button`
      case 'large':
        return tw`w-full md:w-buttonLarge lg:text-2xl lg:py-6 py-[17px] rounded-40`
      case 'wide':
        return tw`min-w-[240px] px-9 lg:min-w-[296px] py-2 lg:py-15px`
      case 'team':
        return tw`min-w-[193px] py-9.5px lg:py-15px px-7 lg:px-10`
      default:
        return tw`py-2.5 lg:py-4 w-button`
    }
  }}
  //arrow svg path colored
  svg {
    path {
      stroke: black;
      transition: stroke 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
  }
  //arrow bounce ani
  @media (hover: hover) {
    &:hover {
      svg {
        animation: bounceX 1s infinite ease-in-out;
      }
    }
  }
  //primary & default variants with white arrow on hover
  ${({ variant }) => {
    if (variant !== 'third') {
      return css`
        @media (hover: hover) {
          &:hover {
            color: white;
            svg {
              animation: bounceX 1s infinite ease-in-out;
              path {
                stroke: white;
              }
            }
          }
        }
      `
    }
  }}
`
