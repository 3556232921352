import React from 'react'
import tw, { styled } from 'twin.macro'

export const BigScreenWrapper = ({ children }) => {
  return <Component>{children}</Component>
}

const Component = styled.div`
  ${tw`mx-auto overflow-x-hidden whitespace-pre-line bg-white shadow-lg max-w-screen-fhd`};
  opacity: 0;
  animation: fadeIn01 0.4s ease-in-out forwards;
  animation-delay: 0.1s;
`
