import { useEffect, useState } from 'react'

export const useScrollToTop = () => {
  const [goToTop, setGoToTop] = useState(false)

  useEffect(() => {
    !!window && window.addEventListener('scroll', handleScroll)
    return () => {
      !!window && window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const scrollTop = window.pageYOffset
    const pageHeight = document.body.offsetHeight
    if (scrollTop < pageHeight / 1.5) {
      setGoToTop(false)
    } else {
      setGoToTop(true)
    }
  }

  const scrollToTop = () => {
    !!window && window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return { scrollToTop, goToTop }
}
