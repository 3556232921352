import React from 'react'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { Parallax } from 'react-scroll-parallax'
import { BigText } from './BigText'

export const ParallaxBigText = ({
  className = '',
  valuesMobile = [-180, 220],
  valuesTablet = [-118, 94],
  valuesDesktop = [-103, 94],
  color = '#7BFF8D',
  textClassName = '',
  children,
}) => {
  const { isTablet } = useBreakpoint()
  return (
    <>
      {/* Between mobile and 1700px - large text */}
      <Parallax
        translateY={isTablet ? valuesMobile : valuesTablet}
        className={`${className} 3xl:hidden`}
      >
        <BigText color={color} className={`${textClassName}`}>
          {children}
        </BigText>
      </Parallax>
      {/* Above 1700px screens - different translate for a better effect */}
      <Parallax
        translateY={valuesDesktop}
        className={`${className} hidden 3xl:block`}
      >
        <BigText color={color} className={`${textClassName}`}>
          {children}
        </BigText>
      </Parallax>
    </>
  )
}
