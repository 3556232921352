import React from 'react'

export const CurvedArrowTwo = ({ ...props }) => {
  return (
    <svg
      width={props.width}
      height="104"
      viewBox="0 0 349 104"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMid meet"
      {...props}
    >
      <path
        d="M63.9851 2.03555L3.02734 41.333L42.3248 102.291"
        stroke="white"
        strokeWidth="3"
      />
      <path
        d="M4 41.501C4 41.501 288.5 100.501 357 41.501"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  )
}
