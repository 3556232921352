import React, { createRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Logo, Smile } from '@elements/svg'
import tw, { styled } from 'twin.macro'
import { useNavLinks } from '@hooks/useNavLinks'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { LanguageSwitcher } from '@elements'

const BurgerIcon = ({ navbarState, toggleNavbarState }) => (
  <StyledBurgerIcon onClick={toggleNavbarState}>
    <span
      id="top-line"
      className={`origin-top-left ${!!navbarState && 'top-line-active'}`}
    ></span>
    <span
      id="mid-line"
      className={`${!!navbarState && 'mid-line-active'}`}
    ></span>
    <span
      id="bottom-line"
      className={`origin-bottom-right ${
        !!navbarState && 'bottom-line-active'
      } !mb-0`}
    ></span>
  </StyledBurgerIcon>
)

export const Navbar = ({ location }) => {
  const [navbarState, setNavbarState] = useState(false)
  const [scrolledState, setScrolledState] = useState(false)
  const { navLinksData, lang } = useNavLinks()
  const toggleNavbarState = () => setNavbarState((curr) => !curr)

  //check if the location is changing and if it is, close the navbar
  useEffect(() => {
    setNavbarState(false)
  }, [location.pathname])

  const handleMobileLogoClick = () => {
    setNavbarState(false)
  }

  //active link data
  const { isMobile } = useBreakpoint()
  const locationPathName = location.pathname

  useEffect(() => {
    if (!!navbarState && !!isMobile) document.body.classList.add('wrapper')
    if (!navbarState || !isMobile) document.body.classList.remove('wrapper')

    if (!!isMobile) {
      const checkPageScrolled = () => {
        if (!!window && window.scrollY > 2) {
          setScrolledState(true)
        } else {
          setScrolledState(false)
        }
      }
      !!window && window.addEventListener('scroll', checkPageScrolled)
      return () => {
        !!window && window.removeEventListener('scroll', checkPageScrolled)
      }
    }
  }, [isMobile, navbarState])

  const NavLinks = ({ mobile = false }) => {
    const navLinkRef = navLinksData.map(() => createRef())

    const getSmileAnimation = (path) => {
      if (locationPathName.includes(path))
        return (
          <Smile className="absolute w-7 right-3 md:-right-0.5 xl:right-0 -bottom-6 md:-bottom-8 xl:bottom-[-30px]" />
        )
    }

    if (!!mobile) {
      return navLinksData.map((link, index) => {
        return (
          <StyledLi key={index}>
            <StyledLink
              key={index}
              to={link.to}
              ref={navLinkRef[index]}
              onClick={toggleNavbarState}
              className={locationPathName.includes(link.to) ? 'active' : ''}
            >
              {link.label}
              {getSmileAnimation(link.to)}
            </StyledLink>
          </StyledLi>
        )
      })
    }
    return navLinksData.map((link, index) => {
      return (
        <StyledLi key={index}>
          <StyledLink
            key={index}
            to={link.to}
            ref={navLinkRef[index]}
            className={locationPathName.includes(link.to) ? 'active' : ''}
          >
            {link.label}
            {getSmileAnimation(link.to)}
          </StyledLink>
        </StyledLi>
      )
    })
  }

  return (
    <div
      className={`fixed z-[2000] w-full md:static ${
        locationPathName === `/${lang}/` ? 'bg-gray' : 'bg-background'
      }`}
    >
      <NavWrap
        className={`flex items-center justify-between h-full py-2.5 md:pt-5 md:pb-5 mx-auto max-w-master px-mobile sm:px-tablet lg:px-desktop transition-shadow duration-500 ${
          scrolledState && 'shadow-md md:shadow-none'
        }`}
      >
        <Link to={`/${lang}/`} aria-label="Go to the homepage">
          <Logo className="box-content max-w-[30vw]" />
        </Link>
        <div className="flex items-center md:hidden">
          <LanguageSwitcher />
          <BurgerIcon
            navbarState={navbarState}
            toggleNavbarState={toggleNavbarState}
          />
        </div>
        {/* Mobile nav menu*/}
        <div
          id="mobileNav"
          className={`fixed flex flex-col items-center h-full cursor-pointer md:hidden`}
        >
          <Drawer
            className={`bg-white fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-screen gap-y-12 ${
              !!navbarState && '!transform-none'
            }`}
          >
            <Link
              to={`/${lang}/`}
              aria-label="Go to the homepage"
              className="absolute top-0 left-0 py-2.5 md:pt-5 md:pb-5 px-mobile sm:px-tablet"
              onClick={handleMobileLogoClick}
            >
              <Logo className="box-content max-w-[30vw] " />
            </Link>
            <NavLinks mobile />
          </Drawer>
        </div>
        {/* Desktop nav menu*/}
        <ul
          id="menu"
          className={`items-center justify-center flex-1 hidden min-h-full max-w-max md:gap-x-3 lg:gap-x-10 md:flex ${
            locationPathName === '/' ? 'nav-entry-ani' : ''
          }`}
        >
          <NavLinks />
          <LanguageSwitcher />
        </ul>
      </NavWrap>
    </div>
  )
}
const Drawer = styled.ul`
  transform: translate3d(-101vw, 0, 0.01px);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`

const StyledBurgerIcon = styled.div`
  ${tw`relative z-50 py-2 pl-2 ml-5 cursor-pointer`};
  span {
    ${tw`block mb-1 bg-black w-[33px] h-[3px] rounded-[3px]`}
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  .top-line-active {
    transform: rotate(45deg) translate(0, -4px);
  }
  .mid-line-active {
    ${tw`opacity-0`}
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  .bottom-line-active {
    transform: rotate(-45deg);
  }
`

const StyledLink = styled(Link)`
  ${tw`relative flex items-center px-6 py-2 text-2xl transition-colors duration-300 md:text-lg md:min-h-full md:py-0 md:px-2 lg:px-3 hover:text-blue`}
`
const StyledLi = styled.li`
  .active {
    ${tw`text-blue`}
  }
  :not(.active) > svg {
    ${tw`!opacity-0`}
  }
`
const NavWrap = styled.div`
  ul.nav-entry-ani {
    opacity: 0;
    animation: fadeIn01 0.4s ease-in-out forwards;
    animation-delay: 0;
  }
`
