import React from 'react'

export const Logo = ({ className = '' }) => {
  return (
    <svg
      width="191"
      height="50"
      viewBox="0 0 191 50"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
    >
      <path
        d="M2.62455 37.4294C4.07404 37.4294 5.24909 36.2595 5.24909 34.8164C5.24909 33.3732 4.07404 32.2034 2.62455 32.2034C1.17505 32.2034 0 33.3732 0 34.8164C0 36.2595 1.17505 37.4294 2.62455 37.4294Z"
        fill="black"
      />
      <path
        d="M13.5459 11.8644C9.6445 11.8644 6.66528 13.7006 5.03381 16.3842V0H0.28125V29.096C0.990587 28.8136 1.84179 28.6017 2.693 28.6017C3.5442 28.6017 4.3954 28.7429 5.10474 29.096V23.7994C5.10474 19.5621 7.58742 16.1723 12.269 16.1723C16.9507 16.1723 18.2275 18.2203 18.2275 23.2345V37.3588H23.051V22.2458C23.051 15.8898 20.0008 11.8644 13.6168 11.8644H13.5459Z"
        fill="black"
      />
      <path
        d="M49.9377 35.0284C51.4263 35.0284 52.6332 33.8269 52.6332 32.3447C52.6332 30.8626 51.4263 29.6611 49.9377 29.6611C48.449 29.6611 47.2422 30.8626 47.2422 32.3447C47.2422 33.8269 48.449 35.0284 49.9377 35.0284Z"
        fill="black"
      />
      <path
        d="M43.9098 33.8276C43.2714 33.9688 42.5621 34.0395 41.7818 34.0395C37.0293 34.0395 34.2629 31.144 33.9082 25.918H53.2022V24.0818C53.2022 16.7372 48.5205 11.7937 41.4272 11.7937C34.3338 11.7937 29.2266 17.1609 29.2266 24.9293C29.2266 32.6977 34.2629 37.9236 41.64 37.9236C49.0171 37.9236 44.9029 37.7118 46.2507 37.2174C45.1157 36.37 44.1936 35.1694 43.8389 33.6864L43.9098 33.8276ZM41.4981 15.8191C45.7541 15.8191 48.4496 18.5734 48.4496 22.6694H34.0501C34.6885 18.2909 37.313 15.8191 41.4981 15.8191Z"
        fill="black"
      />
      <path
        d="M77.3199 17.7965C78.7694 17.7965 79.9444 16.6267 79.9444 15.1836C79.9444 13.7404 78.7694 12.5706 77.3199 12.5706C75.8704 12.5706 74.6953 13.7404 74.6953 15.1836C74.6953 16.6267 75.8704 17.7965 77.3199 17.7965Z"
        fill="black"
      />
      <path
        d="M77.3186 21.3982C75.6871 21.3982 74.2684 20.7626 73.1335 19.7739L68.3809 32.6271L61.1457 12.5706H56.1094L66.182 38.7711L65.3308 41.1723C64.1249 44.3502 62.3515 44.7033 60.0107 44.7033H56.8187V48.5875C58.0955 48.87 59.4433 49.0819 61.0038 49.0819C64.8342 49.0819 67.3169 47.3163 69.0193 43.0084L77.3895 21.3276L77.3186 21.3982Z"
        fill="black"
      />
      <path
        d="M86.187 44.2091C87.6365 44.2091 88.8116 43.0393 88.8116 41.5962C88.8116 40.153 87.6365 38.9832 86.187 38.9832C84.7375 38.9832 83.5625 40.153 83.5625 41.5962C83.5625 43.0393 84.7375 44.2091 86.187 44.2091Z"
        fill="black"
      />
      <path
        d="M104.058 12.5707L103.703 16.808C102.142 13.7007 99.0213 11.8645 94.9781 11.8645C87.7429 11.8645 83.2031 17.373 83.2031 24.3645C83.2031 31.356 87.672 36.7939 94.7653 36.7939C101.859 36.7939 102.001 35.099 103.632 32.1329V37.147C103.632 41.8786 100.582 44.9153 95.9003 44.9153C91.2186 44.9153 93.0629 44.6329 91.928 44.0679C91.2896 45.5509 90.0837 46.6809 88.5941 47.3165C90.5802 48.5877 93.0629 49.2233 95.9003 49.2233C103.561 49.2233 108.385 44.2091 108.385 36.4408V12.5707H104.129H104.058ZM95.5456 32.6272C90.864 32.6272 87.9557 28.9549 87.9557 24.2939C87.9557 19.6329 90.793 16.0312 95.6165 16.0312C100.44 16.0312 103.348 19.4916 103.348 24.2939C103.348 29.0961 100.369 32.6272 95.5456 32.6272Z"
        fill="black"
      />
      <path
        d="M118.601 17.161C120.051 17.161 121.226 15.9912 121.226 14.5481C121.226 13.1049 120.051 11.9351 118.601 11.9351C117.152 11.9351 115.977 13.1049 115.977 14.5481C115.977 15.9912 117.152 17.161 118.601 17.161Z"
        fill="black"
      />
      <path
        d="M147.046 49.9294C148.496 49.9294 149.671 48.7595 149.671 47.3164C149.671 45.8732 148.496 44.7034 147.046 44.7034C145.597 44.7034 144.422 45.8732 144.422 47.3164C144.422 48.7595 145.597 49.9294 147.046 49.9294Z"
        fill="black"
      />
      <path
        d="M163.43 12.5706L156.053 32.6271L148.818 12.5706H143.781L153.854 38.7711L153.003 41.1723C152.719 41.9491 152.435 42.5141 152.08 43.0084C153.144 44.209 153.783 45.692 153.783 47.3869C153.783 49.0819 153.783 47.3869 153.783 47.4576C154.989 46.4689 155.911 45.0564 156.691 43.079L168.466 12.6412H163.501L163.43 12.5706Z"
        fill="black"
      />
      <path
        d="M173.218 36.4406C174.668 36.4406 175.843 35.2707 175.843 33.8276C175.843 32.3845 174.668 31.2146 173.218 31.2146C171.769 31.2146 170.594 32.3845 170.594 33.8276C170.594 35.2707 171.769 36.4406 173.218 36.4406Z"
        fill="black"
      />
      <path
        d="M184.361 23.6582L179.963 22.5989C177.48 21.9633 176.274 20.904 176.274 19.2091C176.274 17.5142 177.977 15.6074 180.956 15.6074C183.935 15.6074 185.566 17.161 185.637 19.7034H190.248C190.177 14.9718 186.56 11.9351 181.098 11.9351C175.636 11.9351 171.734 14.8305 171.734 19.4209C171.734 24.0113 173.862 25.2825 178.26 26.3418L182.658 27.3305C185.283 27.9661 186.063 29.0255 186.063 30.7204C186.063 32.4153 184.148 34.3927 180.743 34.3927C177.338 34.3927 180.105 34.3927 179.75 34.3927C179.679 35.7345 179.182 37.0057 178.331 38.065C179.041 38.2062 179.821 38.2062 180.601 38.2062C186.56 38.2062 190.603 35.2401 190.603 30.5085C190.603 25.7769 188.475 24.8588 184.29 23.8701L184.361 23.6582Z"
        fill="black"
      />
      <path
        d="M133.849 12.5706V25.7062C133.849 31.0734 131.437 33.8276 127.039 33.8276C122.641 33.8276 121.081 31.7796 121.081 26.483V20.2683C120.3 20.6214 119.52 20.7626 118.669 20.7626C117.818 20.7626 117.037 20.6214 116.328 20.2683V27.966C116.328 34.0395 119.591 37.9943 125.408 37.9943C131.224 37.9943 132.43 36.2287 133.849 33.6158L134.345 37.3587H138.601V12.5706H133.849Z"
        fill="black"
      />
    </svg>
  )
}
