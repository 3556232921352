import React, { createContext, useContext, useReducer } from 'react'
import contentEN from '@content/en'

let initialState = {
  init: false,
  content: contentEN,
  language: '',
}

const stateReducer = (state, payload) => {
  switch (payload.action) {
    case 'SET_INIT':
      return { ...state, init: payload.value }
    case 'SET_CONTENT':
      return { ...state, content: payload.value }
    case 'SET_LANGUAGE':
      return { ...state, language: payload.value }
    default:
      return state
  }
}

export const StateContext = createContext({})

export const StateContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const StateContextStore = {
    init: state.init,
    initHandle: (value) => dispatch({ action: 'SET_INIT', value }),
    dispatch,
    content: state.content,
    contentHandle: (value) => dispatch({ action: 'SET_CONTENT', value }),
    language: state.language,
    languageHandle: (value) => dispatch({ action: 'SET_LANGUAGE', value }),
  }

  return (
    <StateContext.Provider value={StateContextStore}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
