import React from 'react'
import { changeLocale } from 'gatsby-plugin-intl'
import { useLocale } from '@hooks/useLocale'
import tw, { styled } from 'twin.macro'

const Line = ({ className = '' }) => (
  <svg
    width="2"
    height="28"
    viewBox="0 0 2 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className}`}
  >
    <path d="M0.999991 0.5L0.999999 27.5" stroke="black" />
  </svg>
)

export const LanguageSwitcher = () => {
  const { lang } = useLocale()

  return (
    <Switcher className="relative flex">
      <span
        className={`pr-3 text-lg ${lang === 'en' ? 'font-primaryBold' : ''}`}
        onClick={() => changeLocale('en')}
      >
        EN
      </span>
      <Line className="" />
      <span
        className={`pl-3 text-lg ${lang === 'pl' ? 'font-primaryBold' : ''}`}
        onClick={() => changeLocale('pl')}
      >
        PL
      </span>
    </Switcher>
  )
}

const Switcher = styled.li`
  span {
    ${tw`transition-colors cursor-pointer sm:hover:text-blue`}
  }
`
