import { Link } from 'gatsby'
import React from 'react'
import tw, { styled, css } from 'twin.macro'
import {
  ButtonArrow,
  ButtonArrowMobile,
  greenButton,
  primaryButton,
  secondaryButton,
  third,
} from './Button'

export const GatsbyLink = ({
  variant = 'primary', // primary, secondary, third, secondaryTransparent
  size = 'medium', //medium, large, small, wide
  withArrow = false,
  border = '#f5f5f5',
  to = '/',
  children,
  ...props
}) => {
  return (
    <StyledLink variant={variant} size={size} to={to} {...props}>
      {!!withArrow && (
        <span className="relative flex items-center justify-center">
          {children}{' '}
          {(size === 'small' || size === 'medium') && (
            <span className="ml-4">
              <ButtonArrowMobile />
            </span>
          )}
          {size !== 'small' && size !== 'medium' && (
            <span className="w-10 lg:w-[50px] ml-4 lg:ml-5">
              <span className="block md:hidden">
                <ButtonArrowMobile />
              </span>
              <span className="hidden md:block">
                <ButtonArrow />
              </span>
            </span>
          )}
        </span>
      )}
      {!withArrow && children}
    </StyledLink>
  )
}

const StyledLink = styled(Link)`
  ${tw`relative inline-block text-base text-center select-none rounded-30 sm:text-lg lg:text-xl`}
  -webkit-tap-highlight-color: transparent;
  ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return primaryButton
      case 'secondary':
        return secondaryButton
      case 'green':
        return greenButton
      case 'third':
        return third
      default:
        return primaryButton
    }
  }}
  ${({ size }) => {
    switch (size) {
      case 'small':
        return tw`px-9 py-9.5px lg:py-3.5`
      case 'medium':
        return tw`py-2 lg:py-4 w-button`
      case 'large':
        return tw`w-full md:w-buttonLarge lg:text-2xl lg:py-6 py-[17px] rounded-40`
      case 'wide':
        return tw`min-w-[240px] px-9 lg:min-w-[296px] py-2 lg:py-15px`
      case 'team':
        return tw`min-w-[193px] py-9.5px lg:py-15px px-7 lg:px-10`
      default:
        return tw`py-2.5 lg:py-4 w-button`
    }
  }}
  //arrow svg path colored
  svg {
    path {
      stroke: black;
      transition: stroke 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
  }
  //arrow bounce ani
  &:hover {
    svg {
      animation: bounceX 1s infinite ease-in-out;
    }
  }
  //primary & default variants with white arrow on hover
  ${({ variant }) => {
    if (variant !== 'third') {
      return css`
        &:hover {
          color: white;
          svg {
            animation: bounceX 1s infinite ease-in-out;
            path {
              stroke: white;
            }
          }
        }
      `
    }
  }}
`
