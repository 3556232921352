import { useLocation } from '@reach/router'
import { useBreakpoint } from '@hooks/useBreakpoint'

export const useLocale = (string = '', string2= '') => {
  const location = useLocation()
  const lang = location.pathname.split('/')[1]
  const { isMobile } = useBreakpoint()
  const shortJoinUsPL =
    lang === 'pl' && isMobile ? `${string.split(' ')[0]}!` : string
    
  const shortJob2Label = lang === 'pl' ? isMobile ? `Student - tworzenie banerów` : string2 : isMobile ? `Student - banners development` : string2

  return { lang, shortJoinUsPL, shortJob2Label }
}
