import './src/styles/global.css'
import Layout from '@root/Layout'
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { StateContextProvider } from './src/context/stateContext'

export const wrapPageElement = ({ element, props }) => {
  return (
    <StateContextProvider>
      <ParallaxProvider>
        <Layout {...props}>{element}</Layout>
      </ParallaxProvider>
    </StateContextProvider>
  )
}
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const TRANSITION_DELAY = !!location.hash ? 0 : 300
  if (location.action === 'PUSH') {
    window.setTimeout(
      () =>
        window.scroll({
          left: 0,
          top: 0,
        }),
      TRANSITION_DELAY
    )
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }
  return false
}

export const onRouteUpdate = ({ location }) => scrollToAnchor(location)

const scrollToAnchor = (location) => {
  if (!!location && !!location.hash) {
    setTimeout(() => {
      const hash = location.hash.replace(/^#(\d)/, '#\\3$1')
      const item = document.querySelector(`${hash}`).offsetTop
      window.scrollTo({
        top: item - 100,
        behavior: 'smooth',
      })
    }, 0)
  }
  return true
}

export const onClientEntry = () => {
  if (!localStorage.getItem('gatsby-intl-language')) {
    localStorage.setItem('gatsby-intl-language', 'pl')
  }
}
