import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { ToTopButton } from '@elements'
import { Logo } from '@elements/svg'
import { useNavLinks } from '@hooks/useNavLinks'
import { useScrollToTop } from '@hooks/useScrollToTop'
import { useStateContext } from '@context/stateContext'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { withPrefix } from 'gatsby'
import { useLocale } from '@hooks/useLocale'

export const Footer = ({ location }) => {
  const { navLinksData } = useNavLinks()
  const [withLogo, setWithLogo] = useState(false)
  const { scrollToTop } = useScrollToTop()
  const { lang } = useLocale()
  const {
    content: {
      footer: { companyInfo, email, privacyLink },
    },
  } = useStateContext()

  useEffect(() => {
    if (location.pathname.includes('career')) setWithLogo(true)
    if (!location.pathname.includes('career')) setWithLogo(false)
  }, [location.pathname])

  const Bow = ({ className = '' }) => (
    <svg
      width="100%"
      height="265"
      viewBox="0 0 1920 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} hidden md:block bow`}
      preserveAspectRatio="xMidYMin slice"
    >
      <path d="M1920 1C839.593 592.75 0 1 0 1V9.83212" stroke="#C4C4C4" />
    </svg>
  )

  const BowMobile = ({ className = '' }) => (
    <svg
      width="100%"
      height="266"
      viewBox="0 0 320 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} md:hidden bow`}
      preserveAspectRatio="xMidYMin meet"
    >
      <path d="M709 2C91.1424 593.75 -389 2 -389 2V10.8321" stroke="#C4C4C4" />
    </svg>
  )

  const NavLinks = () => (
    <>
      {[
        ...navLinksData,
        {
          label: privacyLink.label,
          to: `${withPrefix(`${lang}`)}${privacyLink.to}`,
        },
      ].map((link, index) => (
        <li key={index}>
          <AniLink key={index} to={link.to} paintDrip hex="#7BFF8D">
            {link.label}
          </AniLink>
        </li>
      ))}
    </>
  )

  return (
    <FooterWrap withLogo={withLogo}>
      {!withLogo && (
        <div
          dangerouslySetInnerHTML={{ __html: email }}
          className="z-10 text-2xl transition-colors md:text-4xl hover:text-blue"
        ></div>
      )}
      {!!withLogo && (
        <div onClick={scrollToTop} className="z-50 cursor-pointer">
          <Logo />
        </div>
      )}
      <LinkBox>
        <NavLinks />
      </LinkBox>
      <Bow className="absolute" />
      <BowMobile
        className={
          withLogo ? 'absolute translate-y-8' : 'absolute translate-y-4'
        }
      />
      <div className="pt-12 pb-8 md:pt-48 md:pb-0">
        <Info isAddress>
          <p>{companyInfo.street}</p>
          <p>{companyInfo.city}</p>
        </Info>
        <Info>
          <p>{companyInfo.NIP}</p>
          <p>{companyInfo.REGON}</p>
          <p>{companyInfo.KRS}</p>
        </Info>
      </div>
      <ToTopButton className="flex md:hidden" />
    </FooterWrap>
  )
}

const LinkBox = styled.ul`
  ${tw`z-10 flex flex-col py-8 text-base text-center gap-x-10 md:flex-row md:pb-0 md:text-xl md:pt-11`}
  li {
    ${tw`pb-4 transition-colors duration-300 md:pb-0 hover:text-blue`}
  }
`
const Info = styled.div`
  ${tw`flex flex-col justify-center text-sm text-center md:flex-row md:text-base`};
  ${({ isAddress }) => isAddress && tw`flex-row`};
  p:not(:last-child) {
    &:after {
      ${tw`content-[""] md:content-["|"] md:px-2`};
      ${({ isAddress }) => isAddress && tw`content-["|"] px-2`};
    }
  }
`

const FooterWrap = styled.footer`
  ${tw`relative flex flex-col items-center pt-20 pb-16 bg-background md:pb-28 xl:pb-28 md:pt-32`};
  ${({ withLogo }) => withLogo && tw`md:pt-24`};

  .bow {
    path {
      stroke: ${({ withLogo }) => withLogo && '#2962FF'};
    }
  }
`
