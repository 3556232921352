import React, { useLayoutEffect, useRef } from 'react'
import tw, { styled } from 'twin.macro'
import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
gsap.registerPlugin(MotionPathPlugin)

export const Smile = ({
  className = '',
  delayed = false,
  disableAnimation = false,
}) => {
  const circleRef = useRef()
  const pathRef = useRef()
  const duration = delayed ? 1 : 0.8
  const delay = delayed ? 0.5 : 0.1

  useLayoutEffect(() => {
    gsap.to(circleRef.current, {
      duration,
      delay,
      ease: 'sine.inOut',
      keyframes: {
        opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      },
      motionPath: {
        path: pathRef.current,
        align: pathRef.current,
        alignOrigin: [0.5, 0.5],
      },
    })
    gsap.fromTo(
      pathRef.current,
      { strokeDashoffset: 89, strokeDasharray: 89 },
      {
        strokeDashoffset: 0,
        duration,
        delay,
        ease: 'sine.inOut',
        keyframes: {
          opacity: [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        },
      }
    )
  }, [])
  return (
    <SVGSmile
      width="100%"
      height="61"
      viewBox="0 0 78 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className} overflow-visible ${
        !!disableAnimation && 'disable-animation'
      }`}
    >
      <path
        ref={pathRef}
        d="M7.05862 53.6909C34.9513 62.1955 68.1522 45.2369 69.1922 7.99989"
        stroke="#2962FF"
        strokeWidth="3"
        strokeLinecap="round"
        className={`${className} ${!!delayed ? 'delayed' : 'instant'}`}
      />
      <circle
        ref={circleRef}
        cx="69"
        cy="8"
        r="8"
        fill="#2962FF"
        className={`${className} ${!!delayed ? 'delayed' : 'instant'}`}
      />
    </SVGSmile>
  )
}

const SVGSmile = styled.svg`
  circle,
  path {
    opacity: 0;
  }
`
