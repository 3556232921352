import React from 'react'
import tw, { styled } from 'twin.macro'

export const Circle = ({
  innerRef,
  className = '',
  text,
  children,
  withBackground = false,
  iconScaleFactor = 54,
}) => {
  return (
    <div
      className={` whitespace-pre-line max-w-max mx-auto w-full ${className} z-10 text-center`}
      ref={innerRef}
    >
      <CircleWrapper className={`${!withBackground && 'bg-blue'}`}>
        <IconWrapper iconScaleFactor={iconScaleFactor}>{children}</IconWrapper>
      </CircleWrapper>
      <h3 className="text-lg font-primary">{text}</h3>
    </div>
  )
}
const CircleWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center mx-auto mb-4 text-center rounded-full`};
  width: clamp(7.5rem, 6.875rem + 3.125vw, 9.375rem);
  height: clamp(7.5rem, 6.875rem + 3.125vw, 9.375rem);
  ~ h3 {
    max-width: 285px;
  }
`
const IconWrapper = styled.div`
  width: ${({ iconScaleFactor }) => `${iconScaleFactor}%`};
`
