import { useStateContext } from '@context/stateContext'
import { useLocation } from '@reach/router'

export const useNavLinks = () => {
  const { content } = useStateContext()
  const navigation = content?.navigation ?? []
  const location = useLocation()

  const lang = location.pathname.split('/')[1]

  const navLinksData = Object.keys(navigation).map((key) => ({
    label: navigation[key],
    to: `/${lang}/${key}/`,
  }))

  return { navLinksData, lang }
}
