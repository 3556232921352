import React from 'react'

export const Arrow = ({ className = '' }) => {
  return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 354 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${className}`}
      >
        <path
          d="M52.2842 0.999997L1 52.2842L52.2842 103.568"
          stroke="#2962FF"
        />
        <line x1="1" y1="52.5" x2="354" y2="52.5" stroke="#2962FF" />
      </svg>
  )
}
