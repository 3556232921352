import React from 'react'
import tw, { styled } from 'twin.macro'

export const BigText = ({
  children,
  color = '#7BFF8D',
  className = '',
  ...props
}) => {
  return (
    <StyledBigText color={color} className={className} {...props}>
      {children}
    </StyledBigText>
  )
}

const StyledBigText = styled.h2`
  ${tw`text-center font-primaryMedium`};
  font-size: clamp(3.875rem, -0.35rem + 21.125vw, 25rem);
  color: ${({ color }) => color};
`
