import React from 'react'

export const HeartIcon = ({ className = '' }) => {
  return (
    <svg
      width="100%"
      height="62"
      viewBox="0 0 70 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M34.908 9.57066C38.9102 3.43037 44.3685 0.264273 51.617 0.471508C62.6449 0.790036 70.6533 10.2269 69.3683 20.9878C68.5726 27.7843 64.8847 33.2146 60.783 38.4223C53.9642 47.0917 45.5739 54.2566 36.8574 61.0647C35.5445 62.0932 34.168 62.1123 32.879 61.0647C22.607 52.9326 12.701 44.4437 5.4087 33.5485C2.22604 28.8013 0.0578537 23.7509 0.236878 17.9061C0.479556 10.3344 5.7031 3.47258 13.1943 1.22753C16.8875 0.0926557 20.8595 0.122183 24.5342 1.31182C28.2089 2.50146 31.3952 4.78936 33.631 7.8437C34.0089 8.39249 34.3908 8.87988 34.908 9.57066ZM67.033 16.9851C67.033 11.1096 61.8413 4.75053 54.4177 3.108C47.1374 1.49618 39.9605 5.02685 36.5152 11.8042C36.189 12.4489 35.3456 13.3086 34.8046 13.2702C34.1999 13.228 33.4758 12.3645 33.1297 11.7006C29.5492 4.87717 22.4558 1.47698 15.1715 3.15021C7.7957 4.84263 2.79892 11.052 2.70742 18.5508C2.64774 23.6242 4.60508 28.0491 7.374 32.1861C14.4037 42.7014 23.9199 50.9485 33.8657 58.762C34.2635 59.0882 35.3695 59.0767 35.8548 58.762C37.1553 57.8783 38.3952 56.9143 39.5666 55.8761C47.6108 49.1794 55.3685 42.2216 61.348 33.6176C64.4988 29.0699 67.0966 24.3035 67.033 16.9851Z"
        fill="white"
      />
    </svg>
  )
}
