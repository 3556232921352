import React from 'react'

export const CurvedArrow = ({ className = '' }) => {
  return (
    <svg
      width="100%"
      height="228"
      viewBox="0 0 423 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}
      preserveAspectRatio="xMaxYMid slice"
    >
      <path
        d="M363.865 112.617L412.574 58.8818L358.839 10.1721"
        stroke="#2962FF"
      />
      <path
        d="M411.594 58.9707C411.594 58.9707 74.5544 45.2354 -77.2433 178.049"
        stroke="#2962FF"
      />
    </svg>
  )
}
