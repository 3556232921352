import React, { useState } from 'react'
import { Button } from './Button'
import tw, { styled, css } from 'twin.macro'

export const Form = ({ data }) => {
  const { fields, button } = data
  const [values, setValues] = useState({ name: '', email: '', message: '' })
  const [formState, setFormState] = useState(null)

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setValues({ name: '', email: '', message: '' })
  }

  const mapFormFields = fields.map((field, index) => {
    if (field.type === 'textarea') {
      return (
        <Textarea
          key={field.name + index}
          name={field.name}
          placeholder={field.placeholder}
          required={field.required}
          value={values[field.name]}
          onChange={handleChange}
          className="col-span-2"
        />
      )
    }
    return (
      <Input
        key={field.name + index}
        type={field.type ?? 'text'}
        name={field.name}
        placeholder={field.placeholder ?? ''}
        required={field.required}
        value={values[field.name]}
        onChange={handleChange}
      />
    )
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-5 sm:gap-y-7">
        {mapFormFields}
      </div>
      <Button
        size="small"
        variant="third"
        withArrow={true}
        className="block mx-auto mt-7 sm:mt-10"
      >
        {button}
      </Button>
    </form>
  )
}
const sharedStyles = css`
  ${tw`bg-transparent border border-black !outline-none rounded-30 px-6 md:min-h-[61px] text-base md:text-xl placeholder:text-black focus:border-2 py-3`};
  &:focus {
    padding: 11px 23px;
  }
`

const Input = styled.input`
  ${sharedStyles}
  ${tw`col-span-2 sm:col-span-1`};
`
const Textarea = styled.textarea`
  ${sharedStyles}
  ${tw`min-h-[158px] lg:min-h-[240px] resize-y`};
`
