import React, { useEffect } from 'react'
import SEO from './Seo'
import { Navbar } from './Navbar'
import { Footer } from './Footer'
import { useScrollToTop } from '@hooks/useScrollToTop'
import { BigScreenWrapper, ToTopButton } from '@elements'
import { useStateContext } from '@context/stateContext'
import contentEN from '@content/en'
import contentPL from '@content/pl'
import { useLocale } from '@hooks/useLocale'

const Layout = ({ children, location }) => {
  const { goToTop } = useScrollToTop()
  const { lang } = useLocale()

  useEffect(() => {
    switch (lang) {
      case 'en':
        contentHandle(contentEN)
        break
      case 'pl':
        contentHandle(contentPL)
        break
      default:
        contentHandle(contentEN)
    }
  }, [lang])

  const { contentHandle, content } = useStateContext()
  if (!content) return null

  return (
    <>
      <SEO />
      <BigScreenWrapper>
        {!!lang && (
          <>
            <Navbar location={location} />
            <main key="main" className="pt-[70px] md:pt-0">
              {children}
              <ToTopButton
                className={`hidden md:flex ${
                  !!goToTop
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-48'
                }`}
              />
            </main>
            <Footer location={location} />
          </>
        )}
      </BigScreenWrapper>
    </>
  )
}

export default Layout
