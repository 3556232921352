import React from 'react'

export const StarIcon = ({ className = '' }) => {
  return (
    <svg
      width="100%"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M45.0109 35.3228L45.1566 36.2073L46.0411 36.353L72.7329 40.7496L46.0411 45.1461L45.1566 45.2918L45.0109 46.1763L40.6143 72.8681L36.2178 46.1763L36.0721 45.2918L35.1875 45.1461L8.49577 40.7496L35.1875 36.353L36.0721 36.2073L36.2178 35.3228L40.6143 8.63103L45.0109 35.3228Z"
        stroke="white"
        strokeWidth="2.5"
      />
      <path
        d="M15.6323 12.7277L15.6649 13.1558L16.0929 13.1883L17.3097 13.2809L16.0929 13.3735L15.6649 13.4061L15.6323 13.8341L15.5397 15.0509L15.4471 13.8341L15.4146 13.4061L14.9865 13.3735L13.7698 13.2809L14.9865 13.1883L15.4146 13.1558L15.4471 12.7277L15.5397 11.511L15.6323 12.7277Z"
        stroke="white"
      />
      <path
        d="M65.9554 67.4962L65.988 67.9243L66.416 67.9568L69.8525 68.2183L66.416 68.4798L65.988 68.5124L65.9554 68.9404L65.6939 72.3769L65.4324 68.9404L65.3999 68.5124L64.9718 68.4798L61.5354 68.2183L64.9718 67.9568L65.3999 67.9243L65.4324 67.4962L65.6939 64.0598L65.9554 67.4962Z"
        stroke="white"
      />
    </svg>
  )
}
